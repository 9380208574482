import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  class: "container",
  style: {"flex-wrap":"wrap"},
  id: "listings"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderCard = _resolveComponent("OrderCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.listings, (listing) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "media",
        key: listing.OrderDocumentId
      }, [
        _createVNode(_component_OrderCard, {
          listing: listing,
          isDark: $props.isDark
        }, null, 8, ["listing", "isDark"])
      ]))
    }), 128))
  ]))
}