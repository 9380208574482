
    import { defineComponent } from 'vue';
    import { mapActions, mapGetters } from 'vuex';

    export default defineComponent({
        methods: {
            ...mapActions(['setForecasts']),
            ...mapActions({ toggleDarkMode: "toggleDarkMode" }),
            ...mapActions({ logout: "logout" })
        },
        computed: {
            ...mapGetters({ isDark: "getdarkMode" }),
            darkModeButtonText() {
                return this.isDark ? 'Light Mode' : 'Dark Mode';
            }
        },
        mounted() {
            //this.$store.dispatch('setForecasts')
            //this.setForecasts();
        }
    });



