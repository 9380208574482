
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'Tiers',
  methods: {
    ...mapActions({ refreshTiers: "setPriceTiers" }),
    ...mapActions({ createTier: "newPriceTiers" }),
    ...mapActions({ deleteTier: "deletePriceTier" }),
    storePrepend (storeKey = "") {
      return storeKey ? `-${storeKey}` : '';
    },
    clearFilters () {
      this.userSearch = null;
      this.tierSearch = null;
      this.partitionSearch = null;
      this.storeSearch = null;
      this.refreshTiers();
    },
    isSearch () {
      return this.userSearch !== null || this.tierSearch !== null || this.partitionSearch !== null || this.storeSearch !== null;
    },
    isValidCreate () {
      return this.userSearch !== null && this.tierSearch !== null && this.partitionSearch !== null;
    },
    saveNew () {
      this.createTier({user: this.userSearch, tierName: this.tierSearch, tierPartition: this.partitionSearch, storePartition: this.storeSearch})
        .then(() => {
          this.userSearch = null;
          this.refreshTiers({tierName: this.tierSearch, tierPartition: this.partitionSearch, storePartition: this.storeSearch});
        })
    },
    saveEdit (tier: any = null, tierUpdate: string) {
      this.createTier({user: tier.userEmail, tierName: tierUpdate, tierPartition: tier.partitionKey, storePartition: tier.storePartitionKey})
        .then(() => {
          this.setEdit(null);
          this.refreshTiers({tierName: this.tierSearch, tierPartition: this.partitionSearch, storePartition: this.storeSearch});
        })
      },
    setEdit (tier: any = null) {
      this.editValue = tier ? tier.tierName : '';
      this.editFlag = tier ? `${tier.userEmail}-${tier.partitionKey}${this.storePrepend(tier.storePartitionKey)}` : '';
    },
    confirmDelete (tier: any = null) {
      if (!tier) {
        return false;
      }
      if (confirm(`Are you sure you want to delete the ${tier.partitionKey}${this.storePrepend(tier.storePartitionKey)} tier for ${tier.userEmail}`)) {
        this.deleteTier({user: tier.userEmail, tierPartition: tier.partitionKey, storePartition: tier.storePartitionKey})
          .then(() => {
            this.refreshTiers({tierName: this.tierSearch, tierPartition: this.partitionSearch, storePartition: this.storeSearch});
          })
      }
    }
  },
  data() {
    return {
      userSearch: null,
      tierSearch: null,
      partitionSearch: null,
      storeSearch: null,
      editFlag: "",
      editValue: "",
    }
  },
  created() {
    this.refreshTiers();
  },
  computed: {
    ...mapGetters({ tiers: "getPriceTiers" }),
    ...mapGetters({ loading: "getLoadingStatus" }),
    ...mapGetters({ isDark: "getdarkMode" })
  }
});
