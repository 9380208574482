

    import { defineComponent } from 'vue';
    import { mapActions, mapGetters } from 'vuex';

    export default defineComponent({
        data () {
            return {
                username: '',
                password: '',
                submitted: false
            }
        },
        computed: {
            ...mapGetters({ loggingIn: "getLoadingStatus" }),
            ...mapGetters({ isDark: "getdarkMode" }),
            ...mapGetters({ me: "getMe" }),
        },
        created() {
            this.$store.dispatch('setMe');
        },
        methods: {
            handleSubmit () {
                this.submitted = true;
                const { username, password } = this;
                const { dispatch } = this.$store;
                if (username && password) {
                    this.$store.dispatch('login', { username, password });
                }
            }
        }
    });
