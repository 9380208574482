

    import { defineComponent } from 'vue';
    import { mapGetters } from 'vuex';

    export default defineComponent({
        props: ['id'],
        data () {
            return {
                listing: ''
            }
        },
        computed: {
        },
        mounted() {
            
            const tickets = this.$store.getters.getProducts
            console.log(tickets)
            const listingString = tickets.hits.hits.filter((item: any) => {
                return (item._source.sku == this.id)
            } )
            this.listing = JSON.stringify(listingString, null, 2)
        }
    });
