
    import { defineComponent } from 'vue';
    import { mapActions, mapGetters } from 'vuex';
    import ReviewList from '@/components/ReviewList.vue';
    import VueJsonPretty from 'vue-json-pretty';
    import 'vue-json-pretty/lib/styles.css';

    export default defineComponent({
        name: 'Reviews',
        components: {
            ReviewList
        },
        methods: {
            ...mapActions({ refreshTickets: "setReviews" }),
            ...mapActions({ toggleDarkMode: "toggleDarkMode" }),
            updatePartitionKey(e:any) {
                this.$store.commit("setPartitionKey",  e.target.value);
            }
        },
        created() {
            // reset login status
            this.$store.dispatch('setReviews');
        },
        computed: {
            ...mapGetters({ tickets: "getReviews" }),
            ...mapGetters({ loading: "getLoadingStatus" }),
            ...mapGetters({ isDark: "getdarkMode" }),
            ...mapGetters({ partitionKey: "getPartitionKey" })
            
        }
    });
