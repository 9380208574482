
import { mapActions } from 'vuex';
import ProductCard from '@/components/ProductCard.vue';

export default {
  name: 'ProductList',
  props: ['listings', 'isDark'],
  data() {
    return {
      notification: null,
    }
  },
  methods: {
    
  },
  components: {
    ProductCard
  }
}
