import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/styles.css'
import './assets/site.css'

import axios from 'axios'
import VueAxios from 'vue-axios'

const url = 'http://' + process.env.VUE_APP_API_URL + '/api/';

function replaceUrlParam(url:any, paramName:any, paramValue:any)
{
    if (paramValue == null) {
        paramValue = '';
    }

    console.log('Replace source', url);
    const pattern = new RegExp('\\b('+paramName+'=).*?(&|#|$)');
    if (url.search(pattern)>=0) {
        url = url.replace(pattern,'$1' + paramValue + '$2');
        console.log('Replace result', url);
        return url;
    }
    url = url.replace(/[?#]$/,'');
    url = url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
    console.log('Replace result', url);
    return url;
}


// axios.interceptors.response.use(
//     (res) => {
//         console.log("Intercepted", res);
//         if (res.config.method == 'get' && res.data.code == 400) {
//             if (res.config.url !== "/login") {
//                 const refresh_token = localStorage.getItem('refresh-token');
//                 console.log("Refreshing at ", url + "user/refresh");

//                 axios.post(url + "user/refresh?partitionKey=" + store.getters.getPartitionKey, {
//                     refreshToken: refresh_token
//                     })
//                     .then(function (response) {

//                         console.log("rs", response);

//                         localStorage.setItem('token', response.data.result)
//                         localStorage.setItem('refresh-token', response.data.meta.refreshToken)

//                         let newUrl = replaceUrlParam(res.config.url, "token", response.data.result);
//                         newUrl = replaceUrlParam(newUrl, "fail", "false");

//                         //return new Promise((resolve, reject) => {
//                             return axios.get(newUrl); //.then(response => {
//                             //         return resolve(response);
//                             //     }).catch((err) => {
//                             //         return reject(err);
//                             //     })
//                             //});
//                         })
//                     .catch(function (error) {
//                         console.log(error);
//                         });
                    
                    
//             }
//         }

//         return res;
//     },
//     async (err) => {
      
//       return Promise.reject(err);
//     }
//   );


const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueAxios, axios)
app.mount('#app')
