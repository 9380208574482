
import { mapActions } from 'vuex';
import ReviewCard from '@/components/ReviewCard.vue';

export default {
  name: 'ReviewList',
  props: ['listings', 'isDark'],
  data() {
    return {
      notification: null,
    }
  },
  methods: {
    
  },
  components: {
    ReviewCard
  }
}
