
    import { defineComponent } from 'vue';
    import { mapActions, mapGetters } from 'vuex';
    import ProductList from '@/components/ProductList.vue';

    export default defineComponent({
        name: 'Catalog',
        components: {
            ProductList
        },
        methods: {
            ...mapActions({ refreshTickets: "setProducts" }),
            ...mapActions({ toggleDarkMode: "toggleDarkMode" }),
            updatePartitionKey(e:any) {
                this.$store.commit("setPartitionKey",  e.target.value);
            }
        },
        created() {
            // reset login status
            this.$store.dispatch('setProducts');
        },
        computed: {
            ...mapGetters({ tickets: "getProducts" }),
            ...mapGetters({ loading: "getLoadingStatus" }),
            ...mapGetters({ isDark: "getdarkMode" }),
            ...mapGetters({ partitionKey: "getPartitionKey" })
            
        }
    });
