

    import { defineComponent } from 'vue';
    import { mapGetters } from 'vuex';
    import VueJsonPretty from 'vue-json-pretty';
    import 'vue-json-pretty/lib/styles.css';

    export default defineComponent({
        props: ['id'],
        data () {
            return {
                expandDepth: 3,
                deepCollapse: true,
                path: {}
            }
        },
        components: {
            //VueJsonPretty
        },
        computed: {
            ...mapGetters({ listing: 'getOrder' }),
            ...mapGetters({ isDark: "getdarkMode" }),
            productsTable() {
                return this.createProductsTable()
            },
            checksTable() {
                return this.createChecksTable()
            }

        },
        methods: {
            productRowHtml(product: any) {
            // creating a single table row of user data
            if (product) {
                let html = ''
                Object.entries(product).forEach(e => {
                if (e[0] !== 'address' && e[0] !== 'company') {
                    html += `<td>${e[1]}</td>`
                } else {
                    html += `<td>cannot display</td>`
                }
                })
                return html
            }
            return ''
            },
            productRowHtmlSimple(product: any) {
            // creating a single table row of user data
            if (product) {
                let html = ''
                html += `<td style="text-align: left;"  >${product.sku}</td>`
                html += `<td style="text-align: left;"  >${product.name}</td>`
                html += `<td style="text-align: center;">${product.qty}</td>`
                html += `<td style="text-align: left;"  >${product.type_id}</td>`
                html += `<td style="text-align: right;" >${product.price_incl_tax?.toFixed(2)}</td>`
                html += `<td style="text-align: right;" >${product.special_price_incl_tax?.toFixed(2)}</td>`
                
                return html
            }
            return ''
            },
            checkRowHtmlSimple(check: any) {
            // creating a single table row of user data
            if (check) {
                let html = ''
                html += `<td style="text-align: left;"  >${check.checkType}</td>`
                html += `<td style="text-align: left;"  >${check.name}</td>`
                html += `<td style="text-align: left;">${check.description}</td>`
                
                return html
            }
            return ''
            },
            productRows(products: any[]) {
            // creating the table rows for user data
            if (products && products.length) {
                let html = ''
                products.forEach(e => {
                html += `<tr>${this.productRowHtmlSimple(e)}</tr>`
                })
                return html
            }
            return ''
            },
            checkRows(checks: any[]) {
            // creating the table rows for user data
            if (checks && checks.length) {
                let html = ''
                checks.forEach(e => {
                html += `<tr>${this.checkRowHtmlSimple(e)}</tr>`
                })
                return html
            }
            return ''
            },
            createProductsTable() {
                if (this.listing) {
                    var l = this.listing;
                    //console.log("Listing", l);
                    var r = l.result;
                    //console.log("Result", r);
                    var od = r.azOrderDocument;
                    //console.log("azOrderDocument", od);
                    //console.log("Products", od.products);
                    var products = od.products;
                    // creating the table to insert
                    if (products && products.length) {
                        //const headers = Object.keys(this.products[0])
                        let html = `<table class="table ${this.isDark ? 'has-background-black' : ''} ">`
                        html += '<thead><tr>'
                        
                        html += `<th style="text-align: left;"  >SKU</th>`
                        html += `<th style="text-align: left;"  >Name</th>`
                        html += `<th style="text-align: center;">Qty</th>`
                        html += `<th style="text-align: left;"  >Type</th>`
                        html += `<th style="text-align: right;" >Price</th>`
                        html += `<th style="text-align: right;" >Special Price</th>`
                        
                        html += '</tr></thead>'
                        html += '<tbody>'
                        html += this.productRows(products)
                        html += '</tbody></table>'
                        return html
                    }
                    return 'Mmmmm... No Products???'
                }
            },
            createChecksTable() {
                if (this.listing) {
                    var l = this.listing;
                    //console.log("Listing", l);
                    var r = l.result;
                    //console.log("Result", r);
                    var od = r.azOrderDocument;
                    //console.log("azOrderDocument", od);
                    //console.log("Products", od.products);
                    var checks = r.checks;
                    // creating the table to insert
                    if (checks && checks.length) {
                        //const headers = Object.keys(this.products[0])
                        let html = `<table class="table ${this.isDark ? 'has-background-black' : ''} ">`
                        html += '<thead><tr>'
                        
                        html += `<th style="text-align: left;"  >Type</th>`
                        html += `<th style="text-align: left;"  >Name</th>`
                        html += `<th style="text-align: left;">Description</th>`
                        
                        html += '</tr></thead>'
                        html += '<tbody>'
                        html += this.checkRows(checks)
                        html += '</tbody></table>'
                        return html
                    }
                    return 'Mmmmm... No Products???'
                }
            }
        },
        mounted() {
            
            //this.$store.dispatch('setOrder');
        },
        created() {
            this.$store.commit("setOrderReferenceNumber",  this.id);
            this.$store.dispatch('setOrder', this.id);
        }
    });
