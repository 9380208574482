
    import { defineComponent } from 'vue';
    import { mapActions, mapGetters } from 'vuex';
    import OrderList from '@/components/OrderList.vue';

    export default defineComponent({
        name: 'Orders',
        components: {
            OrderList
        },
        methods: {
            ...mapActions({ refreshTickets: "setOrders" }),
            ...mapActions({ toggleDarkMode: "toggleDarkMode" }),
            updateStatusFilter(e:any) {
                this.$store.commit("setStatusFilter",  e.target.value);
            }
        },
        created() {
            // reset login status
            this.$store.dispatch('setOrders');
        },
        computed: {
            ...mapGetters({ tickets: "getOrders" }),
            ...mapGetters({ loading: "getLoadingStatus" }),
            ...mapGetters({ isDark: "getdarkMode" }),
            ...mapGetters({ statusFilter: "getStatusFilter" })
        }
    });
