 import { defineComponent } from 'vue';
    import { mapActions, mapGetters } from 'vuex';
    import VueJsonPretty from 'vue-json-pretty';
    import 'vue-json-pretty/lib/styles.css';

    export default defineComponent({
        name: 'Home',
        data () {
            return {
                expandDepth: 3,
                deepCollapse: true,
                path: {}
            }
        },
        components: {
            VueJsonPretty
        },
        methods: {
            ...mapActions({ toggleDarkMode: "toggleDarkMode" })
            //refreshWeather() {
            //this.$store.dispatch("setForecasts");
            //    this.setForecasts();
            //}
        },
        created() {
            // reset login status
            this.$store.dispatch('setInfo');
            this.$store.dispatch('setPartitions');
        },
        computed: {
            ...mapGetters({ partitions: "getPartitions" }),
            ...mapGetters({ loading: "getLoadingStatus" }),
            ...mapGetters({ isDark: "getdarkMode" })
        }
    });
