 import { defineComponent } from 'vue';
    import { mapActions, mapGetters } from 'vuex';

    export default defineComponent({
        name: 'Home',
        methods: {
            //...mapActions({ refreshTickets: "setInfo" }),
            //...mapActions({ toggleDarkMode: "toggleDarkMode" })
            //refreshWeather() {
            //this.$store.dispatch("setForecasts");
            //    this.setForecasts();
            //}
        },
        created() {
            // reset login status
            //this.$store.dispatch('setInfo');
        },
        computed: {
            //...mapGetters({ info: "getInfo" }),
            //...mapGetters({ loading: "getLoadingStatus" }),
            //...mapGetters({ isDark: "getdarkMode" }),
            //darkModeButtonText() {
            //    return this.isDark ? 'Light Mode' : 'Dark Mode';
            //}
        }
    });
