
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import moment from 'moment';

export default defineComponent({
    name: 'ReviewCard',
    props: ['listing', 'isDark'],
    methods: {
        formatDate(value: string) {
                if (value) {
                    return moment(String(value)).format('DD MMM YYYY HH:mm')
                }
                return 'N/A'
            },
            ...mapActions({ approveReview: "approveReview" }),
            ...mapActions({ rejectReview: "rejectReview" })
    },
    computed: {
        }
    })

