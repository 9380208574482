import { createStore } from 'vuex'
import axios from 'axios'

type Forecasts = {
    date: string
}[];

//const url = 'https://vs-api-qa.azurewebsites.net/api/';
//const url = 'https://advancefunctionsv3.azurewebsites.net/api/';
const url = 'https://' + process.env.VUE_APP_API_URL + '/api/';

const headers = { Accept: "application/json" };

function replaceUrlParam(url:any, paramName:any, paramValue:any)
{
    if (paramValue == null) {
        paramValue = '';
    }

    console.log('Replace source', url);
    const pattern = new RegExp('\\b('+paramName+'=).*?(&|#|$)');
    if (url.search(pattern)>=0) {
        url = url.replace(pattern,'$1' + paramValue + '$2');
        console.log('Replace result', url);
        return url;
    }
    url = url.replace(/[?#]$/,'');
    url = url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
    console.log('Replace result', url);
    return url;
}

export default createStore({
    state: {
        loadingStatus: false,
        darkMode: false,
        token: '',
        refreshToken: '',
        forecasts: [] as Forecasts,
        products: {},
        datapumpstatus: { code: 200, result: <any>[]},
        orders: {},
        tiers: [],
        reviews: {},
        info: [''],
        productCount: 0,
        partitionKey: 'hupu',
        me: {},
        partitions: {},
        OrderReferenceNumber : '',
        Order: {},
        statusFilter: '!order created'
    },
    mutations: {
        setLoadingStatus(state, status) {
            console.log('setLoadingStatus', status)
            state.loadingStatus = status
        },
        setForecasts(state, payload) {
            console.log(payload)
            state.forecasts = payload as Forecasts
        },
        setProducts(state, payload) {
            console.log(payload)
            state.products = payload
        },
        setDataPumpStatus(state, payload) {
            //console.log(payload)
            state.datapumpstatus = payload
        },
        setOrders(state, payload) {
            console.log(payload)
            state.orders = payload
        },
        setPriceTiers(state, payload) {
            state.tiers = payload
        },
        setReviews(state, payload) {
            console.log(payload)
            state.reviews = payload
        },
        setPartitions(state, payload) {
            console.log(payload)
            state.partitions = payload
        },
        setProductCount(state, payload) {
            state.productCount = payload
        },
        setInfo(state, payload) {
            if (payload) {
                console.log(payload)
                state.info = payload
            }
        },
        setToken(state, payload) {
            console.log(payload)
            state.token = payload
        },
        setMe(state, payload) {
            console.log(payload)
            state.me = payload
        },
        setPartitionKey(state, payload) {
            console.log(payload)
            state.partitionKey = payload
        },
        setStatusFilter(state, payload) {
            console.log(payload)
            state.statusFilter = payload
        },
        setOrderReferenceNumber(state, payload) {
            console.log('********************');
            console.log(payload)
            console.log('********************');
            state.OrderReferenceNumber = payload
        },
        setOrder(state, payload) {
            console.log(payload)
            state.Order = payload
        },
        toggleDarkMode(state) {
            state.darkMode = !state.darkMode
        }
    },
    actions: {
        async setForecasts(state) {
            state.commit("setLoadingStatus", true);
            state.commit("setForecasts", null);
            const r = await fetch(url + 'WeatherForecast', { headers });
            const json = await r.json();
            state.commit("setForecasts", json);
            state.commit("setLoadingStatus", false);
        },
        async setInfo(state) {
            state.commit("setLoadingStatus", true);
            state.commit("setInfo", "Please wait...");


            axios.get(url + 'health?echo=Hello+Advance+Manager', { headers }).then((response) => {
                console.log(response.data)
                state.commit("setInfo", response.data);
              })


            //const r = await fetch(url + 'health?echo=Hello+Advance+Manager', { headers });
            //const json = await r.json();
            //state.commit("setInfo", json);

            state.commit("setLoadingStatus", false);
        },
        async setProducts(state) {

            const token = localStorage.getItem('token');

            state.commit("setLoadingStatus", true);
            state.commit("setProducts", null);
            
            const r = await fetch(url + 'catalog/' + state.getters.getPartitionKey + '/product/_search?size=100', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token //state.getters.getToken
                }
            });
            const json = await r.json();
            state.commit("setProducts", json);
            state.commit("setLoadingStatus", false);
        },
        async setDataPumpStatus(state) {

            const token = localStorage.getItem('token');

            state.commit("setLoadingStatus", true);
            state.commit("setDataPumpStatus", null);

            const thisurl = url + 'manage/datapump?partitionKey=' + state.getters.getPartitionKey + '&token=' + token + '&fail=false';

            axios.get(thisurl, { headers }).then((response) => {
                console.log('setDataPumpStatus response', response.data)

                if (response.data.code == 400) {

                    const refresh_token = localStorage.getItem('refresh-token');
                    console.log("Refreshing at ", url + "user/refresh");
    
                    axios.post(url + "user/refresh?partitionKey=" + this.getters.getPartitionKey, {
                        refreshToken: refresh_token
                        })
                        .then(function (response) {
    
                            console.log("rs", response);
    
                            localStorage.setItem('token', response.data.result)
                            localStorage.setItem('refresh-token', response.data.meta.refreshToken)
    
                            let newUrl = replaceUrlParam(thisurl, "token", response.data.result);
                            newUrl = replaceUrlParam(newUrl, "fail", "false");
    
                            axios.get(newUrl).then(response => {
                                state.commit("setDataPumpStatus", response.data);
                                });
                            })
                        .catch(function (error) {
                            console.log(error);
                            });
                            
                            
                    
                }
                else {
                    state.commit("setDataPumpStatus", response.data);
                }

                state.commit("setLoadingStatus", false);
              })

        },
        async setOrders(state) {

            const token = localStorage.getItem('token');

            state.commit("setLoadingStatus", true);
            state.commit("setOrders", null);
            const r = await fetch(url + 'manage/orders?take=100&status='+state.getters.getStatusFilter+'&partitionKey=' + state.getters.getPartitionKey + '&token=' + token, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });

            const json = await r.json();
            if (json.code == 200) {
                state.commit("setOrders", json.result);
            } else if (json.code == 401) {
                localStorage.removeItem('token');
                state.commit("setOrders", null);
            } else {
                state.commit("setOrders", null);
            }


            state.commit("setLoadingStatus", false);
        },
        async setOrder(state, payload) {

            const token = localStorage.getItem('token');
            console.log('--------------------');
            console.log(payload);
            console.log('--------------------');
            state.commit("setLoadingStatus", true);
            state.commit("setOrder", null);

            const r = await fetch(url + 'manage/check-order?partitionKey=' + state.getters.getPartitionKey + '&orderReferenceNumber=' + payload + '&token=' + token, {
                method: 'get',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });
            const json = await r.json();
            if (json.code == 200) {
                state.commit("setOrder", json);
            } else if (json.code == 401) {
                localStorage.removeItem('token');
                state.commit("setOrders", null);
            } else {
                state.commit("setOrder", json);
            }
            state.commit("setLoadingStatus", false);


        },
        async setPriceTiers(state, data) {
            const token = localStorage.getItem('token');
            if (token) {
                state.commit("setLoadingStatus", true);
                state.commit("setPriceTiers", []);

                let searchParams = "";
                if (data) {
                    searchParams = (data.user ? '&priceTierUserIdOrEmail=' + data.user : '') + 
                    (data.tierName ? '&priceTier=' + data.tierName : '') + 
                    (data.tierPartition ? '&priceTierPartitionKey=' + data.tierPartition : '') + 
                    (data.storePartition ? '&priceTierStorePartitionKey=' + data.storePartition : '');
                }

                const pageSize = 50;
                const r = await fetch(url + 'manage/pricetiergroups?partitionKey=' + state.getters.getPartitionKey + '&token=' + token + "&take=" + pageSize + searchParams, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token //state.getters.getToken
                    }
                });
                const json = await r.json();
                console.log(json);
                if (json.code == 200 || json.code == 204) {
                    state.commit("setPriceTiers", json.result);
                } else if (json.code == 401) {
                    localStorage.removeItem('token');
                    state.commit("setPriceTiers", null);
                } else {
                    state.commit("setPriceTiers", null);
                }
                state.commit("setLoadingStatus", false);
            }
        },
        async newPriceTiers(state, data) {
            const token = localStorage.getItem('token');
            if (token) {
                let body = null;
                if (data) {
                    body = {
                        PartitionKey: data.tierPartition,
                        StorePartitionKey: data.storePartition,
                        UserEmail: data.user,
                        TierName: data.tierName
                    };

                    state.commit("setLoadingStatus", true);
                    const r = await fetch(url + 'manage/pricetiergroups?partitionKey=' + state.getters.getPartitionKey + '&token=' + token, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        },
                        body: JSON.stringify(body)
                    });
                    state.getters.getPriceTiers;
                    state.commit("setLoadingStatus", false);
                }
            }
        },
        async deletePriceTier(state, data) {
            const token = localStorage.getItem('token');
            if (token) {
                let body = null;
                if (data) {
                    body = {
                        partitionKey: data.tierPartition,
                        storePartitionKey: data.storePartition,
                        userEmail: data.user
                    };

                    state.commit("setLoadingStatus", true);
                    const r = await fetch(url + 'manage/pricetiergroups?partitionKey=' + state.getters.getPartitionKey + '&token=' + token, {
                        method: 'DELETE',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        },
                        body: JSON.stringify(body)
                    });
                    state.getters.getPriceTiers;
                    state.commit("setLoadingStatus", false);
                }
            }
        },
        async setReviews(state) {

            const token = localStorage.getItem('token');
            if (token) {
                state.commit("setLoadingStatus", true);
                state.commit("setReviews", null);
                const r = await fetch(url + 'review/list?partitionKey=' + state.getters.getPartitionKey + '&token=' + token, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token //state.getters.getToken
                    }
                });
                const json = await r.json();
                console.log(json);
                state.commit("setReviews", json);
                state.commit("setLoadingStatus", false);
            }
        },
        async setPartitions(state) {

            const token = localStorage.getItem('token');

            state.commit("setLoadingStatus", true);
            state.commit("setPartitions", null);
            const r = await fetch(url + 'manage/partitions?partitionKey=' + state.getters.getPartitionKey + '&token=' + token, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token //state.getters.getToken
                }
            });
            const json = await r.json();
            state.commit("setPartitions", json);
            state.commit("setLoadingStatus", false);
        },
        async setMe(state) {

            state.commit("setMe", null);
            const token = localStorage.getItem('token');
            if (token) {
                state.commit("setLoadingStatus", true);
                try {
                    const r = await fetch(url + 'user/me?partitionKey=' + state.getters.getPartitionKey + '&token=' + token, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token //state.getters.getToken
                        }
                    });
                    const json = await r.json();
                    state.commit("setMe", json);
                } catch (err) {
                    console.error(err);
                    state.commit("setMe", "Error retrieving you profile information. Are you logged in?");
                }
                state.commit("setLoadingStatus", false);

            }
            else {
                state.commit("setMe", "Please log in");
            }
        },
        async approveReview(state, payload) {
            state.commit("setLoadingStatus", true);
            state.commit("setReviews", null);

            console.log(payload)
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const r = await fetch(url + 'review/update?partitionKey=' + state.getters.getPartitionKey + '&token=' + token, {
                        method: 'put',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "sku": payload.sku,
                            "review_id": payload.review_id,
                            "review_status": 1
                        })
                    });
                    const json = await r.json();
                    console.log(json)

                    if (json.code == 200 || json.code == 204) {
                        state.dispatch('setReviews');
                    }
                   
                } catch (err) {
                    console.error(err);
                }
            }

            state.commit("setLoadingStatus", false);
        },
        async rejectReview(state, payload) {
            state.commit("setLoadingStatus", true);
            state.commit("setReviews", null);

            console.log(payload)
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const r = await fetch(url + 'review/update?partitionKey=' + state.getters.getPartitionKey + '&token=' + token, {
                        method: 'put',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "sku": payload.sku,
                            "review_id": payload.review_id,
                            "review_status": 2
                        })
                    });
                    const json = await r.json();
                    console.log(json)

                    if (json.code == 200 || json.code == 204) {
                        state.dispatch('setReviews');
                    }
                   
                } catch (err) {
                    console.error(err);
                }
            }
           
            state.commit("setLoadingStatus", false);
        },

        async login(state, payload) {
            console.log('username = ', payload.username);
            state.commit("setLoadingStatus", true);
            state.commit("setProducts", null);
            //state.commit("setToken", null);
            state.commit("setMe", null);
            console.log(JSON.stringify({
                "username": payload.username,
                "password": payload.password,
            }))

            try {
                const r = await fetch(url + 'user/login?partitionKey=' + state.getters.getPartitionKey, {
                    method: 'post',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "username": payload.username,
                        "password": payload.password,
                    })
                });
                const json = await r.json();
                console.log(json)
                //state.commit("setToken", json);

                localStorage.setItem('token', json.result)
                localStorage.setItem('refresh-token', json.meta.refreshToken)

                if (json.code == 200) {
                    state.dispatch('setMe');
                }

                state.commit("setLoadingStatus", false);
                
            } catch (err) {
                console.error(err);
                state.commit("setMe", "Error logging in: " + err);
            }
        },
        logout(state) {
            localStorage.setItem('token', '')
            state.commit("setProducts", null);
            state.commit("setPartitions", null);
            state.commit("setMe", null);
            console.log('Logged out...')
        },
        toggleDarkMode(state) {
            state.commit("toggleDarkMode")
        }
    },
    modules: {
    },
    getters: {
        getForecasts(state) {
            return state.forecasts
        },
        getProducts(state) {
            return state.products
        },
        getDataPumpStatus(state) {
            // console.log(state.datapumpstatus.result.sort(function (a: any, b: any) {
            //     return a.statusDateTime > b.statusDateTime;
            // }));
            if (state.datapumpstatus && state.datapumpstatus.result) {

                return state.datapumpstatus.result.sort(function (a: any, b: any) {
                    //console.log(a.statusDateTime, b.statusDateTime, a.statusDateTime > b.statusDateTime);

                    if (a.success == b.success) {
                        return a.statusDateTime < b.statusDateTime ? 1 : a.statusDateTime > b.statusDateTime ? -1 : 0;
                    }
                    else if (a.success && !b.success) {
                        return 1;
                    } else {
                        return -1;
                    }

                });
            }

            return null;
        },
        getOrders(state) {
            return state.orders
        },
        getPriceTiers(state) {
            return state.tiers
        },
        getReviews(state) {
            return state.reviews
        },
        getPartitions(state) {
            return state.partitions
        },
        getLoadingStatus(state) {
            return state.loadingStatus
        },
        getdarkMode(state) {
            return state.darkMode
        },
        getToken(state) {
            return state.token
        },
        getInfo(state) {
            return state.info
        },
        getMe(state) {
            return state.me
        },
        getOrder(state) {
            return state.Order
        },
        getPartitionKey(state) {
            return state.partitionKey
        },
        getStatusFilter(state) {
            return state.statusFilter
        }
    }
})
