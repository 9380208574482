
    import { defineComponent } from 'vue';
    import { mapActions, mapGetters } from 'vuex';
    import moment from 'moment';

    export default defineComponent({
        name: 'Catalog',
        components: {
        },
        methods: {
            ...mapActions({ refreshTickets: "setDataPumpStatus" }),
            ...mapActions({ toggleDarkMode: "toggleDarkMode" }),
            updatePartitionKey(e:any) {
                this.$store.commit("setPartitionKey",  e.target.value);
            },
            formatDate(value: string) {
                if (value) {
                    var date = moment(String(value))
                    var timeString = date.format('DD MMM YYYY HH:mm') + ' (' + date.fromNow() + ')';
                    return timeString;
                }
                return 'N/A'
            }
        },
        created() {
            // reset login status
            this.$store.dispatch('setDataPumpStatus');
        },
        computed: {
            ...mapGetters({ tickets: "getDataPumpStatus" }),
            ...mapGetters({ loading: "getLoadingStatus" }),
            ...mapGetters({ isDark: "getdarkMode" }),
            ...mapGetters({ partitionKey: "getPartitionKey" })
            
        }
    });
