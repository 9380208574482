import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"text-align":"left"} }
const _hoisted_2 = { style: {"text-align":"left"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, "Details of " + _toDisplayString(_ctx.id), 1),
    _createElementVNode("pre", _hoisted_2, _toDisplayString(_ctx.listing), 1)
  ], 64))
}